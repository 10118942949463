// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports ** template reducers
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import category from '../../views/apps/categories/CategoryRedux/store/reducer'
import postFeed from '../../views/apps/Features/postFeedRedux/reducer/Reducer'
//new reducer for the app
import admins from '@src/views/apps/users/admin/store/reducer'

// importing the plug and play reduces
import plugAndPlay from './PlugAndPlay/pnpReducer'
import { adAppDisReducer, adReducer } from './adManager'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  // our own reducer for app
  admins,  
  // category redux
  category,
  postFeed,
  plugAndPlay,
  adReducer,
  adAppDisReducer
})

export default rootReducer
