import * as pnpTypes from "./types"

const initialState = {
    error: false,
    success: true,
    loading: false,
    plugs: {},
    country: [],
    pluggedCountries: {},
    selectedCountry: []
}

const plugAndPlay = (state = initialState, action) => {
    switch (action.type) {
        case pnpTypes.ERROR_EDIT_PLUG: {
            break
        }
        case pnpTypes.ERROR_FETCH_COUNTRY: {
            break
        }
        case pnpTypes.ERROR_PLUG_AND_PLAY: {
            break
        }
        case pnpTypes.ERROR_RESET_PLUG_PLAY: {
            break
        }
        case pnpTypes.SUCCESS_EDIT_PLUG: {
            return { ...state, plugs: action.payload }
            break
        }
        case pnpTypes.SUCCESS_FETCH_COUNTRY: {

            return { ...state, country: [...state.country, ...action.payload]  }
            break
        }
        
        case pnpTypes.SUCCESS_GLOBAL_VIEW: { 
            return { ...state, pluggedCountries: action.payload }
        }
        case pnpTypes.SET_DEFAULT_COUNTRY: {
            return { ...state, selectedCountry: [action.payload] }
        }
        case pnpTypes.SUCCESS_PLUG_AND_PLAY: {
            return { ...state, plugs: action.payload }
        }
        case pnpTypes.SUCCESS_RESET_PLUG_PLAY: {
            return { ...state, plugs: action.payload }
        }
        case pnpTypes.FAIL_PLUG_AND_PLAY: {
            break
        }
        case pnpTypes.FAIL_FETCH_COUNTRY: {
            break
        }
        case pnpTypes.FAIL_EDIT_PLUG: {
            break
        }
        case pnpTypes.FAIL_RESET_PLUG_PLAY: {
            break
        }
        default: {
            break
        }
    }
    return state
}


export default plugAndPlay