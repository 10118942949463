// ------------------Initial State----------------
const initialState = {
    render: null
}

const postFeed = (state = initialState, action) => {
    switch (action.type) {
        case "RENDER":
            return { ...state, render: action.data }

        default:
            return { ...state }
    }
}

export default postFeed