/* eslint-disable comma-dangle, semi */

// adReducer.js
import {
    FETCH_ADS_REQUEST,
    FETCH_ADS_SUCCESS,
    FETCH_ADS_FAILURE,
    APPROVEDIS_ADS_SUCCESS,
  } from '../../actions/adManagerAction';
  
  const initialState = {
    ads: [],
    loading: false,
    error: null,
  };
  
  export const adReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ADS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_ADS_SUCCESS:
        return {
          ...state,
          ads: action.payload,
          loading: false,
          error: null,
        };
      case FETCH_ADS_FAILURE:
        return {
          ...state,
          ads: [],
          loading: false,
          error: action.payload,
        };
      case APPROVEDIS_ADS_SUCCESS:
        return {
          ...state,
          status : action.payload,
          loading:false,
          error:null
        };
      default:
        return state;
    }
  };

  export const adAppDisReducer = (state = initialState, action) => {
    switch (action.type) {

      case APPROVEDIS_ADS_SUCCESS:
        return {
          ...state,
          status : action.payload,
          loading:false,
          error:null
        };
      default:
        return state;
    }
  };
  