
import mock from '../mock'
const data = {
  profileData: {
    header: {
      avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
      username: 'Kitty Allanson',
      designation: 'UI/UX Designer',
      coverImg: require('@src/assets/images/profile/user-uploads/timeline.jpg').default
    },
    userAbout: {
      about: 'Tart I love sugar plum I love oat cake. Sweet ⭐️ roll caramels I love jujubes. Topping cake wafer.',
      joined: 'November 15, 2015',
      lives: 'New York, USA',
      email: 'bucketful@fiendhead.org',
      website: 'www.pixinvent.com'
    },
    suggestedPages: [

      {
        avatar: require('@src/assets/images/avatars/12-small.png').default,
        username: 'Peter Reed',
        subtitle: 'Company',
        favorite: false
      },
      {
        avatar: require('@src/assets/images/avatars/1-small.png').default,
        username: 'Harriett Adkins',
        subtitle: 'Company',
        favorite: false
      },
      {
        avatar: require('@src/assets/images/avatars/10-small.png').default,
        username: 'Juan Weaver',
        subtitle: 'Company',
        favorite: false
      },
      {
        avatar: require('@src/assets/images/avatars/3-small.png').default,
        username: 'Claudia Chandler',
        subtitle: 'Company',
        favorite: false
      },
      {
        avatar: require('@src/assets/images/avatars/5-small.png').default,
        username: 'Earl Briggs',
        subtitle: 'Company',
        favorite: true
      },
      {
        avatar: require('@src/assets/images/avatars/6-small.png').default,
        username: 'Jonathan Lyons',
        subtitle: 'Beauty Store',
        favorite: false
      }
    ],
    twitterFeeds: [
      {
        imgUrl: require('@src/assets/images/avatars/5-small.png').default,
        title: 'Gertrude Stevens',
        id: 'tiana59 ',
        tags: '#design #fasion',
        desc: 'I love cookie chupa chups sweet tart apple pie ⭐️ chocolate bar.',
        favorite: false
      },
      {
        imgUrl: require('@src/assets/images/avatars/12-small.png').default,
        title: 'Lura Jones',
        id: 'tiana59 ',
        tags: '#vuejs #code #coffeez',
        desc: 'Halvah I love powder jelly I love cheesecake cotton candy. 😍',
        favorite: true
      },
      {
        imgUrl: require('@src/assets/images/avatars/1-small.png').default,
        title: 'Norman Gross',
        id: 'tiana59 ',
        tags: '#sketch #uiux #figma',
        desc: 'Candy jelly beans powder brownie biscuit. Jelly marzipan oat cake cake.',
        favorite: false
      }
    ],
    post: [
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: 'Public . 1 jan 2020 at 1:16 AM',
        postText:
          'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
        postImg1: require('@src/assets/images/profile/post-media/14.jpg').default,
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.05k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Trine Lynes'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'Lilian Nenes'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Alberto Glotzbach'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'George Nordic'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Vinnie Mostowy'
          }
        ],
        likedCount: 140,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '54 Min ago',
            youLiked: false
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Jackey Potter',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
            comentsTime: '34 Min ago',
            youLiked: true
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Jackey Potter',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
            comentsTime: '34 Min ago',
            youLiked: true
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: 'Public . 1 jan 2020 at 1:16 AM',
        postText:
          'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
        postImg2: require('@src/assets/images/profile/post-media/6.jpg').default,
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.05k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Trine Lynes'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'Lilian Nenes'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Alberto Glotzbach'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'George Nordic'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Vinnie Mostowy'
          }
        ],
        likedCount: 140,
        detailedComments: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
          //   username: 'Kitty Allanson',
          //   comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
          //   comentsTime: '84 Min ago',
          //   youLiked: false
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
            username: 'Jackey Potter',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
            comentsTime: '14 Min ago',
            youLiked: true
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: '11 Dec 2019 at 1:16 AM',
        postText:
          'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
        postImg3: require('@src/assets/images/profile/post-media/13.jpg').default,
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Kori Scargle'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'Florinda Mollison'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Beltran Endley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Kara Gerred'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Sophey Bru'
          }
        ],
        likedCount: 271,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '14 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: '11 Dec 2019 at 1:16 AM',
        postText:
          'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
        postMultipalVideo: require('@src/assets/images/profile/post-media/10.jpg').default,
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Kori Scargle'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'Florinda Mollison'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Beltran Endley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Kara Gerred'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Sophey Bru'
          }
        ],
        likedCount: 271,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '1 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Event Posted by Kitty Allanson',
        postEventText:
          'Events, by their very nature, are about connections. Through your bright event description, you can boost attendance, sponsorship, or hosting of an event; make new',
        postTime: '11 Dec 2019 at 1:16 AM',
        postEvent: require('@src/assets/images/svg/event_2.png').default,
        title: 'Get started with live music near you',
        categries: 'Entertainment',
        date: 'Sat, 20th to Sun, 21st Feb, 2021.',
        time: '11:00 AM - 08:00 PM',
        location: 'Clarendon Park Road, Leicester, LE2 3, United Kingdom',
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Kori Scargle'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'Florinda Mollison'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Beltran Endley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Kara Gerred'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Sophey Bru'
          }
        ],
        likedCount: 271,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '1 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: '11 Dec 2019 at 1:16 AM',
        postText:
          'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
        postMultipalImage: require('@src/assets/images/profile/post-media/10.jpg').default,
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Kori Scargle'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'Florinda Mollison'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Beltran Endley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Kara Gerred'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Sophey Bru'
          }
        ],
        likedCount: 271,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '1 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: '11 Dec 2019 at 1:16 AM',
        postThought: 'Fire is spreading in the Amazon forests',
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Kori Scargle'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'Florinda Mollison'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Beltran Endley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Kara Gerred'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Sophey Bru'
          }
        ],
        likedCount: 271,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '34 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: '11 Dec 2019 at 1:16 AM',
        postText2: '#Musiccelebration, #Musiccelebration, #music, #party, #music Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industry',
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Kori Scargle'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'Florinda Mollison'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Beltran Endley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Kara Gerred'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Sophey Bru'
          }
        ],
        likedCount: 271,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '34 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: '11 Dec 2019 at 1:16 AM',
        postReadMore: 'The Government of India may sue or be sued by the name of the Union of India and the Government of a State may sue or be sued by the name of the State and may, subject to any provisions which may be made by Act of Parliament or of the Legislature of such State enacted by virtue of powers conferred by this Constitution, sue or be sued in relation to their respective affairs in the like cases as the Dominion of India and the corresponding Provinces or the corresponding Indian States might have sued or been sued if this Constitution had not been enacted',
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Kori Scargle'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'Florinda Mollison'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Beltran Endley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Kara Gerred'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Sophey Bru'
          }
        ],
        likedCount: 271,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '34 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: '11 Dec 2019 at 1:16 AM',
        postThreat: 'Fire is spreading in the Amazon forests',
        alert: 'severe ',
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Kori Scargle'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'Florinda Mollison'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Beltran Endley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Kara Gerred'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Sophey Bru'
          }
        ],
        likedCount: 271,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '34 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: '11 Dec 2019 at 1:16 AM',
        postPolls: 'Which is the best car of 2020?',
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Kori Scargle'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'Florinda Mollison'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Beltran Endley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Kara Gerred'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Sophey Bru'
          }
        ],
        likedCount: 271,
        detailedComments: [
          {
            // avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '34 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: '12 Dec 2019 at 1:16 AM',
        postVid: require('@src/assets/images/video/video2.mp4').default,
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Dehlia Bolderson'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'De Lamy'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Vallie Kingsley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Nadia Armell'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Romonda Aseef'
          }
        ],
        likedCount: 264,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '54 Min ago',
            youLiked: false
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Jackey Potter',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
            comentsTime: '34 Min ago',
            youLiked: true
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '34 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: '12 Dec 2019 at 1:16 AM',
        postShotz: require('@src/assets/images/video/s1.mp4').default,
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Dehlia Bolderson'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'De Lamy'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Vallie Kingsley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Nadia Armell'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Romonda Aseef'
          }
        ],
        likedCount: 264,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '54 Min ago',
            youLiked: false
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Jackey Potter',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
            comentsTime: '34 Min ago',
            youLiked: true
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '34 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson is with',
        postTime: '12 Dec 2019 at 1:16 AM',
        postArticle: 'https://www.youtube.com/embed/6stlCkUDG_s',
        postImg: require('@src/assets/images/profile/post-media/a.jpg').default,
        time: '01 July, 2022  Sports',
        title: 'Wang said China’s position on the South China Sea issue is supported by abundant historical and legal basis.',
        description: 'Birmingham City Football Club is a professional football club in Birmingham, England. Formed in 1875 as Small Heath Alliance, it was renamed Small Heath in 1888, Birmingham in 1905, and Birmingham City in 1943. Since 2011, the first team have competed in the EFL Championship, the second tier of English football',
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Dehlia Bolderson'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'De Lamy'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Vallie Kingsley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Nadia Armell'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Romonda Aseef'
          }
        ],
        likedCount: 264,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '54 Min ago',
            youLiked: false
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Jackey Potter',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
            comentsTime: '34 Min ago',
            youLiked: true
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '34 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson',
        postTime: '12 Dec 2019 at 1:16 AM',
        postRecommend: 'I am seeking recommendation for buying a car',
        postImg: require('@src/assets/images/profile/post-media/r1.jpg').default,
        time: '01 July, 2022  Sports',
        title: 'Wang said China’s position on the South China Sea issue is supported by abundant historical and legal basis.',
        description: 'Birmingham City Football Club is a professional football club in Birmingham, England. Formed in 1875 as Small Heath Alliance, it was renamed Small Heath in 1888, Birmingham in 1905, and Birmingham City in 1943. Since 2011, the first team have competed in the EFL Championship, the second tier of English football ... See more ',
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Dehlia Bolderson'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'De Lamy'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Vallie Kingsley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Nadia Armell'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Romonda Aseef'
          }
        ],
        likedCount: 264,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '54 Min ago',
            youLiked: false
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Jackey Potter',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
            comentsTime: '34 Min ago',
            youLiked: true
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '34 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson',
        postTime: '12 Dec 2019 at 1:16 AM',
        postRecommendImg: require('@src/assets/images/profile/post-media/r.jpg').default,
        time: '01 July, 2022  Sports',
        title: 'I am seeking recommendation for buying a car',
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Dehlia Bolderson'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'De Lamy'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Vallie Kingsley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Nadia Armell'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Romonda Aseef'
          }
        ],
        likedCount: 264,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '54 Min ago',
            youLiked: false
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Jackey Potter',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
            comentsTime: '34 Min ago',
            youLiked: true
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '34 Min ago',
            youLiked: false
          }
        ]
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
        username: 'Kitty Allanson',
        postTime: '12 Dec 2019 at 1:16 AM',
        postPodcast: require('@src/assets/images/profile/post-media/17.jpg').default,
        time: '01 July, 2022  Sports',
        title: 'Ask CDCR San Quintin State Prison 2008. We installed Purex dispensers throughout the prison to combat diseases…and it was a Roaring Success (as in Roaring Drunk) I mean we had Long lines of prisoners fist fighting to use them.',
        likes: '1.25k',
        youLiked: true,
        comments: '1.25k',
        share: '1.25k',
        likedUsers: [
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
          //   username: 'Dehlia Bolderson'
          // },
          // {
          //   avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
          //   username: 'De Lamy'
          // },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Vallie Kingsley'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Nadia Armell'
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Romonda Aseef'
          }
        ],
        likedCount: 264,
        detailedComments: [
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '54 Min ago',
            youLiked: false
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            username: 'Jackey Potter',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
            comentsTime: '34 Min ago',
            youLiked: true
          },
          {
            avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            comentsTime: '34 Min ago',
            youLiked: false
          }
        ]
      }
    ],
    latestPhotos: [
      { img: require('@src/assets/images/profile/user-uploads/user-13.jpg').default },
      { img: require('@src/assets/images/profile/user-uploads/user-02.jpg').default },
      { img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default },
      { img: require('@src/assets/images/profile/user-uploads/user-04.jpg').default },
      { img: require('@src/assets/images/profile/user-uploads/user-05.jpg').default },
      { img: require('@src/assets/images/profile/user-uploads/user-06.jpg').default },
      { img: require('@src/assets/images/profile/user-uploads/user-07.jpg').default },
      { img: require('@src/assets/images/profile/user-uploads/user-08.jpg').default },
      { img: require('@src/assets/images/profile/user-uploads/user-09.jpg').default }
    ],
    suggestions: [
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
        name: 'Peter Reed',
        mutualFriend: '6 Mutual Friends'
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
        name: 'Harriett Adkins',
        mutualFriend: '3 Mutual Friends'
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
        name: 'Juan Weaver',
        mutualFriend: '1 Mutual Friends'
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
        name: 'Claudia Chandler',
        mutualFriend: '16 Mutual Friends'
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
        name: 'Earl Briggs',
        mutualFriend: '4 Mutual Friends'
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-10.jpg').default,
        name: 'Jonathan Lyons',
        mutualFriend: '25 Mutual Friends'
      }
    ],
    polls: [
      {
        name: 'RDJ',
        result: '82%',
        votedUser: [
          {
            img: require('@src/assets/images/portrait/small/avatar-s-12.jpg').default,
            username: 'Tonia Seabold'
          },
          {
            img: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Carissa Dolle'
          },
          {
            img: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
            username: 'Kelle Herrick'
          },
          {
            img: require('@src/assets/images/portrait/small/avatar-s-10.jpg').default,
            username: 'Len Bregantini'
          },
          {
            img: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
            username: 'John Doe'
          }
        ]
      },
      {
        name: 'Chris Hemsworth',
        result: '67%',
        votedUser: [
          {
            img: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
            username: 'Tonia Seabold'
          },
          {
            img: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
            username: 'Carissa Dolle'
          },
          {
            img: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
            username: 'Jonathan Lyons'
          }
        ]
      }
    ]
  }
}

mock.onGet('/profile/data').reply(() => [200, data.profileData])
