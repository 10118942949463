// ------------------Initial State----------------
const initialState = {
    data: [],
    count: {},
    status: null,
    country: [],
    pageIndex: 0,
    pageSize: 10,
    pageCount: 0
}

const category = (state = initialState, action) => {
    switch (action.type) {
        case "RENDER":
            return { ...state, render: action.data }

        case "ADD_COUNTRY":
            return { ...state, country:[...state.country, action.data] }
        
        case "REMOVE_COUNTRY":
            return { ...state, country: state.country.filter((ele) => ele.id !== action.data) }

        case "CHANGE_STATUS":
            return { ...state, status: action.data }

        case "GET_DATA":
            return { ...state, data: action.data }

        case "GET_COUNT": 
            return { ...state, count: action.data }
        
        case "GET_PAGE_COUNT":
            return { ...state, pageCount: action.data }

        case "UPDATE_PAGE_INDEX":
            return { ...state, pageIndex: action.data }

        case "UPDATE_PAGE_SIZE":
            return { ...state, pageSize: action.data }
        default:
            return { ...state }
    }
}

export default category