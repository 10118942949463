//all success responses type
export const SUCCESS_PLUG_AND_PLAY = "SUCCESS_PLUG_AND_PLAY"

export const SUCCESS_FETCH_COUNTRY = "SUCCESS_FETCH_COUNTRY"

export const SUCCESS_EDIT_PLUG = "SUCCESS_EDIT_PLUG"

export const SUCCESS_RESET_PLUG_PLAY = "SUCCESS_RESET_PLUG_PLAY"

export const SET_DEFAULT_COUNTRY = "SET_DEFAULT_COUNTRY"

export const SUCCESS_GLOBAL_VIEW = "SUCCESS_GLOBAL_VIEW"

//ALL ERROR RESPONSES OF PLUG AND PLAY
export const ERROR_PLUG_AND_PLAY = "ERROR_PLUG_AND_PLAY"

export const ERROR_FETCH_COUNTRY = "ERROR_FETCH_COUNTRY"

export const ERROR_EDIT_PLUG = "ERROR_EDIT_PLUG"

export const ERROR_RESET_PLUG_PLAY = "ERROR_RESET_PLUG_PLAY"

export const ERROR_GLOBAL_VIEW = "ERROR_GLOBAL_VIEW"

//all fail responses
export const FAIL_PLUG_AND_PLAY = "FAIL_PLUG_AND_PLAY"
export const FAIL_FETCH_COUNTRY = "FAIL_FETCH_COUNTRY"
export const FAIL_EDIT_PLUG = "FAIL_EDIT_PLUG"
export const FAIL_RESET_PLUG_PLAY = "FAIL_RESET_PLUG_PLAY"

export const FAIL_GLOBAL_VIEW = "FAIL_GLOBAL_VIEW"
