/* eslint-disable*/
// adActions.js
import axios from 'axios';
import apiAuthenticatedCall from '../../api/authApiConfig';
import instance from '../../api/apiConfig';

// Action Types
export const FETCH_ADS_REQUEST = 'FETCH_ADS_REQUEST';
export const FETCH_ADS_SUCCESS = 'FETCH_ADS_SUCCESS';
export const FETCH_ADS_FAILURE = 'FETCH_ADS_FAILURE';
export const APPROVEDIS_ADS_REQUEST = 'APPROVEDIS_ADS_REQUEST';
export const APPROVEDIS_ADS_SUCCESS = 'APPROVEDIS_ADS_SUCCESS';
export const APPROVEDIS_ADS_FAILURE = 'APPROVEDIS_ADS_FAILURE';
   
// Action Creators
export const     fetchAdsRequest = () => ({
  type: FETCH_ADS_REQUEST,
});

export const fetchAdsSuccess = (ads) => ({
  type: FETCH_ADS_SUCCESS,
  payload: ads,
});

export const fetchAdsFailure = (error) => ({
  type: FETCH_ADS_FAILURE,
  payload: error,
});

//toggle approve disapprove
export const approveDisAdsRequest = () => ({
  type: APPROVEDIS_ADS_REQUEST,
});

export const approveDisAdsSuccess = (status) => ({
  type: APPROVEDIS_ADS_SUCCESS,
  payload: status,
});

export const approveDisAdsFailure = (error) => ({
  type: APPROVEDIS_ADS_FAILURE,
  payload: error,
});

// Thunk Action Creator
export const fetchAds =  (body) => {
  const localData = localStorage.getItem("userData")
  const parsedData = JSON.parse(localData)
  const token = parsedData.accessToken
  return async (dispatch) => {
    dispatch(fetchAdsRequest());
    await instance.post('/adminportal/getAds', body, {
      headers: {
       Authorization: `Bearer ${token}` 
             }
         })
    // apiAuthenticatedCall
    //   .post('/adminportal/getAds', body, {
    //     headers: {
    //      Authorization: `Bearer ${token}` 
    //            }
    //        }) // Adjust the URL accordingly
      .then((response) => {
        const ads = response.data;
        dispatch(fetchAdsSuccess(ads));
      })
      .catch((error) => {
        const errorMessage = error.message;
        dispatch(fetchAdsFailure(errorMessage));
      });
  };
};
// Thunk Action Creator
export const pauseAds =  async(body) => {
  const localData = localStorage.getItem("userData")
  const parsedData = JSON.parse(localData)
  const token = parsedData.accessToken
    await instance.post('/adminportal/pauseAd', body, {
      headers: {
       Authorization: `Bearer ${token}` 
             }
         })
      .then((response) => {
        // const ads = response.data;
        console.log(response, "adsPause")
      })
      .catch((error) => {
        console.log("Error", error)
      });
};

// Thunk Action Creator
export const deleteAds =  async(body) => {
  const localData = localStorage.getItem("userData")
  const parsedData = JSON.parse(localData)
  const token = parsedData.accessToken
    await instance.post('/adminportal/deleteAd', body, {
      headers: {
       Authorization: `Bearer ${token}` 
             }
         })
      .then((response) => {
        // const ads = response.data;
        console.log(response, "adsPause")
      })
      .catch((error) => {
        console.log("Error", error)
      });
};

// Thunk Action Creator
export const stopAds =  async(body) => {
  const localData = localStorage.getItem("userData")
  const parsedData = JSON.parse(localData)
  const token = parsedData.accessToken
    await instance.post('/adminportal/stopAd', body, {
      headers: {
       Authorization: `Bearer ${token}` 
             }
         })
      .then((response) => {
        // const ads = response.data;
        console.log(response, "adsPause")
      })
      .catch((error) => {
        console.log("Error", error)
      });
};

// Thunk Action Creator
export const resumeAds =  async(body) => {
  const localData = localStorage.getItem("userData")
  const parsedData = JSON.parse(localData)
  const token = parsedData.accessToken
    await instance.post('/adminportal/resumeAd', body, {
      headers: {
       Authorization: `Bearer ${token}` 
             }
         })
      .then((response) => {
        // const ads = response.data;
        console.log(response, "adsPause")
      })
      .catch((error) => {
        console.log("Error", error)
      });
};

// Thunk Action Creator
export const restoreAds =  async(body) => {
  const localData = localStorage.getItem("userData")
  const parsedData = JSON.parse(localData)
  const token = parsedData.accessToken
    await instance.post('/adminportal/restoreAdsList', body, {
      headers: {
       Authorization: `Bearer ${token}` 
             }
         })
      .then((response) => {
        // const ads = response.data;
        console.log(response, "adsPause")
      })
      .catch((error) => {
        console.log("Error", error)
      });
};

// Thunk Action Creator
export const fetchAdsCount =  (body) => {
  const localData = localStorage.getItem("userData")
  const parsedData = JSON.parse(localData)
  const token = parsedData.accessToken
  return async (dispatch) => {
    dispatch(fetchAdsRequest());
    await instance.post('/adminportal/getAds', body, {
      headers: {
       Authorization: `Bearer ${token}` 
             }
         })
    // apiAuthenticatedCall
    //   .post('/adminportal/getAds', body, {
    //     headers: {
    //      Authorization: `Bearer ${token}` 
    //            }
    //        }) // Adjust the URL accordingly
      .then((response) => {
        const ads = response.data;
        dispatch(fetchAdsSuccess(ads));
      })
      .catch((error) => {
        const errorMessage = error.message;
        dispatch(fetchAdsFailure(errorMessage));
      });
  };
};

export const approveDisapprove = (body) => {
  const localData = localStorage.getItem("userData")
  const parsedData = JSON.parse(localData)
  const token = parsedData.accessToken
  return async(dispatch) => {
    dispatch(approveDisAdsRequest());
    await instance.post('/adminportal/approveDisApproveAd', body, {
      headers: {
       Authorization: `Bearer ${token}` 
             }
         })
    // apiAuthenticatedCall
    //   .post('/adminportal/approveDisApproveAd', body ) // Adjust the URL accordingly
      .then((response) => {
        const status = response.data;
        dispatch(approveDisAdsSuccess(status));
      })
      .catch((error) => {
        const errorMessage = error.message;
        dispatch(approveDisAdsFailure(errorMessage));
      });
  };
};

